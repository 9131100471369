// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Kshitij",
  middleName: "",
  lastName: "Joshi",
  message: " To make a positive contribution to the IT-world no matter how small it is. ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/kshitijmjoshi",
    },
    {
      image: "fa-facebook",
      url: "https://www.facebook.com/kshitijmjoshi",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/blue4life__/",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/kshitijmjoshi/",
    },
    {
      image: "fa-twitter",
      url: "https://www.twitter.com/kshitijchelsea/",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/kshitij.jpg"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/kshitij.jpg"),
  imageSize: 475,
  message:
    "I work as a DevOps/Platform Tech Lead. I am hard-working, tech-savvy & passionate about developing good quality maintainable, and innovative solutions in the least amount of time. I am a quick learner & open to new challenges. I like to learn new technologies & widen my knowledge about existing ones. I also have the ability to work in teams with people coming from different cultures. I have exposure to all phases of the Software Development Life Cycle (SDLC) and technology-driven solutions in collaboration with teams. I have also worked on Agile Development and Test Driven Development. My career goal is to make a positive contribution to the IT-world no matter how small it is.",
  resume: "https://static.kshitij-joshi.com/Kshitij_Resume_2022.pdf",
};

// PROJECTS SECTION
// Setting up project length will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "kshitijmjoshi", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/kshitij.jpg"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/kshitij.jpg"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Nginx", value: 90 },
    { name: "OpenResty", value: 90 },
    { name: "Lua", value: 85 },
    { name: "NodeJS", value: 85 },
    { name: "HTML/CSS & JavaScript", value: 85 },
    { name: "Docker & Kubernetes", value: 80 },
    { name: "Cloudflare & CDNs", value: 85 },
    { name: "Gitlab CI/CD", value: 85 },
  ],
  otherSkills: [
    { name: "Jira & Confluence", value: 80 },
    { name: "Git", value: 90 },
    { name: "Promethus & Grafana", value: 75 },
    { name: "Redis", value: 85 },
    { name: "SQL", value: 60 },
    { name: "SOLR", value: 60 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "If you have any questions, or if you just want to say hi, please feel free to email me at",
  email: "hello@kshitij-joshi.com",
};

const experiences = {
  show: true,
  heading: "Experiences",
  data: [
    {
      role: 'Tech Lead',// Here Add Company Name
      companylogo: require('../assets/img/webmd.jpg'),
      date: 'July 2021 – Present',
    },
    {
      role: 'Sr. Platform Engineer',// Here Add Company Name
      companylogo: require('../assets/img/webmd.jpg'),
      date: 'Dec 2019 – July 2021',
    },
    {
      role: 'Web Developer',// Here Add Company Name
      companylogo: require('../assets/img/webmd.jpg'),
      date: 'March 2019 – Dec 2019',
    },
    {
      role: 'Associate Web Developer',// Here Add Company Name
      companylogo: require('../assets/img/webmd.jpg'),
      date: 'June 2018 – March 2019',
    },
    {
      role: 'Senior Web Developer – Intern',// Here Add Company Name
      companylogo: require('../assets/img/webmd.jpg'),
      date: 'June 2017 – April 2018',
    },
  ]
}

const educations = {
  show: true,
  heading: "Education",
  data: [
    {
      role: 'Masters in Computer Science',// Here Add Company Name
      companylogo: require('../assets/img/njit.png'),
      date: 'Sept 2016 – May 2018',
    },
    {
      role: 'Bachelors in Computer Engineering',// Here Add Company Name
      companylogo: require('../assets/img/mumbai.jpg'),
      date: 'Sept 2012 – May 2016',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences, educations };
